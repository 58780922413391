import axios from "axios"
const baseURL = process.env.VUE_APP_API_URL

const headers = {
  "Content-Type": "application/json",
}

const MainService = axios.create({
  baseURL: baseURL,
  headers: headers,
})

MainService.interceptors.request.use(
  function (config) {
    const key = localStorage.getItem("op")
    if (key != undefined) {
      config.headers["Authorization"] = `Bearer ${localStorage.getItem("op")}`
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

export { MainService }